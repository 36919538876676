<template>
  <div class="bac">
    <div class="main">
      <el-form v-model="subForm">
        <el-form-item>
          <div style="float: left;font-size: .16rem;color:#212121">
            <i>姓</i><i style="margin-left: .3rem;margin-right: .2rem">名：</i>
          </div>
          <input type="text" v-model="subForm.params.name" style=" width: 4.9rem;margin-right: 1.32rem;">
          <p>手机号码：</p>
          <input v-model="subForm.params.phone" style=" width: 4.9rem;">
        </el-form-item>

        <el-form-item>
          <p>所在地址：</p>
          <input type="text" v-model="subForm.params.address" style="width: 4.9rem;margin-right: 1.32rem;">
          <p>工作年限：</p>
          <input v-model="subForm.params.workingYears" style=" width: 4.9rem;"/>
        </el-form-item>
        <el-form-item style="margin-left: -0.32rem;">
          <p>上传身份材料：&nbsp</p>
          <div class="upload">
            <el-upload ref="upload"
                       action="#"
                       accept="image/png,image/gif,image/jpg,image/jpeg"
                       :auto-upload="false"
                       :on-change="cardFace"
                       :show-file-list="false"
                        >
              <p>点击上传文件</p>
              <p>(正面)</p>
            </el-upload>
          </div>
          <div class="upload" style="margin-right: 1.35rem;">
            <el-upload ref="upload"
                       action="#"
                       accept="image/png,image/gif,image/jpg,image/jpeg"
                       :auto-upload="false"
                       :on-change="cardBack"
                       :show-file-list="false">
              <p>点击上传文件</p>
              <p>(反面)</p>
            </el-upload>
          </div>
          <p>个人简介：</p>
          <div style="float: left; width: 4.9rem" >
            <el-input
                v-model="subForm.params.info"
                :autosize="{ minRows: 5, maxRows: 6 }"
                type="textarea"
            />
          </div>
        </el-form-item>
        <el-form-item>
          <div class="role-choice">
            <p >身份类型：</p>
            <el-radio-group v-model="subForm.params.roleType">
              <el-radio label="在职设计师" >在职设计师</el-radio>
              <el-radio label="学生">学生</el-radio>
              <el-radio label="自由职业者">自由职业者</el-radio>
              <el-radio label="设计工作室">设计工作室</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-scrollbar max-height="3rem">
          <div v-for="(item,i) in subForm.params.educationalExperience">
            <el-form-item>
              <div class="education">
                <p>教育经历：</p>
                <div class="time">
                  <el-date-picker
                      style="margin-right: .3rem;
                    width: 1.25rem;
                    height: .38rem"
                      type="date"
                      placeholder="开始时间"
                      v-model="item.beginTime"
                      format="YYYY/MM/DD"
                      value-format="x">
                  </el-date-picker>
                  <el-date-picker
                      style="margin-right: .26rem;
                      width: 1.25rem;
                      height: .38rem"
                      type="date"
                      placeholder="结束时间"
                      v-model="item.endTime"
                      format="YYYY/MM/DD"
                      value-format="x">
                  </el-date-picker>
                </div>
                <input type="text" placeholder="请填写您的学校名称" v-model="item.schoolName">
                <input type="text" placeholder="请填写您的专业名称" v-model="item.professionalTitle">
                <div class="delete">
                  <button @click="eduDel"><i class="fa fa-minus" style="font-size:.18rem;color: #ffffff"></i></button>
                  <i>删除</i>
                </div>
                <div class="add">
                  <button @click="eduAdd"><i style="font-size:.18rem;color: #ffffff" class="fa">&#xf067</i></button>
                  <i>添加</i>
                </div>
              </div>
            </el-form-item>
          </div>
          <div v-for="(item,i) in subForm.params.workExperience">
            <el-form-item>
              <div class="work-experience">
                <p>工作经历：</p>
                <div style="float:left;">
                  <el-date-picker
                      style="margin-right: .3rem;
                          width: 1.25rem;
                          height: .38rem"
                      type="date"
                      placeholder="开始时间"
                      v-model="item.beginTime"
                      format="YYYY/MM/DD"
                      value-format="x">
                  </el-date-picker>
                  <el-date-picker
                      style="margin-right: .3rem;
                          width: 1.25rem;
                          height: .26rem"
                      type="date"
                      placeholder="结束时间"
                      v-model="item.endTime"
                      format="YYYY/MM/DD"
                      value-format="x">
                  </el-date-picker>
                </div>
                <input type="text" placeholder="请填写您的公司名称" v-model="item.companyName">
                <input type="text" placeholder="请填写您的岗位名称" v-model="item.positionName">
                <div class="delete">
                  <button @click="workDel"><i class="fa fa-minus" style="font-size:.18rem;color: #ffffff"></i></button>
                  <i>删除</i>
                </div>
                <div class="add">
                  <button @click="workAdd"><i style="font-size:.18rem;color: #ffffff" class="fa">&#xf067</i></button>
                  <i>添加</i>
                </div>
              </div>
            </el-form-item>
          </div>
          <div v-for="(item,i) in subForm.params.awards">
            <el-form-item>
              <div class="award">
                <p>获奖经历：</p>
                <div style="float:left;">
                  <el-date-picker
                      style="margin-right: .26rem;
              width: 2.84rem;
              height: .38rem"
                      type="date"
                      placeholder="获奖时间"
                      v-model="item.awardDate"
                      format="YYYY/MM/DD"
                      value-format="x">
                  </el-date-picker>
                </div>
                <input type="text" placeholder="请填写获得奖项名称" v-model="item.awardName">
                <input type="text" placeholder="请填写获奖作品名称" v-model="item.workName">
                <div class="delete">
                  <button @click="awardsDel"><i class="fa fa-minus" style="font-size:.18rem;color: #ffffff"></i></button>
                  <i>删除</i>
                </div>
                <div class="add">
                  <button @click="awardsAdd"><i style="font-size:.18rem;color: #ffffff" class="fa">&#xf067</i></button>
                  <i>添加</i>
                </div>
              </div>
            </el-form-item>
          </div>
        </el-scrollbar>

      </el-form>

      <button class="submit" @click="submit">提交认证</button>
    </div>
  </div>
</template>

<script>
let formData = new FormData();
export default {
  name: "DesignCertification",
  data(){
    return{
      iscardFace: false,
      iscardBack: false,

      subForm:{
        params:{
          name:'',
          address:'',
          phone:'',
          workingYears:'',
          info:'',
          roleType:'',
          educationalExperience:[{
            beginTime:'',
            endTime:'',
            schoolName:'',
            professionalTitle:'',
          }],
          workExperience:[{
            beginTime:'',
            endTime:'',
            companyName:'',
            positionName:'',
          }],
          awards:[{
            awardDate:'',
            awardName:'',
            workName:'',
          }]
        },
      }
    }
  },
  methods:{
    submit(){
      formData.append('params',JSON.stringify(this.subForm.params));
      if (!this.iscardFace && !this.iscardBack){
        window.alert('请上传封面');
      }
      this.$http({
        method: 'post',
        url: '/submitDesignerAuthenticate',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        this.$notify({
          title: '上传成功',
          message: '',
          type: 'success'
        });
      })
    },

    cardFace(files, fileList){
      formData.append('idCardFront',fileList[0].raw)
      this.iscardFace = true;
    },

    cardBack(files, fileList){
      formData.append('idCardBack',fileList[0].raw)
      this.iscardBack = true;
    },

    eduAdd(){
      if (this.subForm.params.educationalExperience.length >= 3){
        window.alert('最多只能添加三项');
        return ;
      }
      this.subForm.params.educationalExperience.push({
            beginTime:'',
            endTime:'',
            schoolName:'',
            professionalTitle:'',
      });
    },

    eduDel(){
      if (this.subForm.params.educationalExperience.length <= 1){
        return ;
      }
      this.subForm.params.educationalExperience.pop();
    },

    workAdd(){
      if (this.subForm.params.workExperience.length >= 3){
        window.alert('最多只能添加三项');
        return ;
      }
      this.subForm.params.workExperience.push({
        beginTime:'',
        endTime:'',
        companyName:'',
        positionName:'',
      });
    },

    workDel(){
      if (this.subForm.params.workExperience.length <= 1){
        return ;
      }
      this.subForm.params.workExperience.pop();
    },

    awardsAdd(){
      if (this.subForm.params.awards.length >= 3){
        window.alert('最多只能添加三项');
        return ;
      }
      this.subForm.params.awards.push({
        awardDate:'',
        awardName:'',
        workName:'',
      });
    },

    awardsDel(){
      if (this.subForm.params.awards.length <= 1){
        return ;
      }
      this.subForm.params.awards.pop();
    },
  },
}
</script>

<style scoped>
.bac{
  width: 19.2rem;
  /*height: 10.8rem;*/
  padding-left: 3.58rem;
  padding-top: .65rem;
  background-color: #f4f4f4;
}

input{
  padding-left: .1rem;
}

.main{
  position: relative;
  width: 14.62rem;
  height: 8.78rem;
  background-color: #ffffff;
  padding-top: .8rem;
  padding-left: .95rem;
}

.main p{
  float: left;
  font-size: .16rem;
  color: #212121;
  margin-right: .2rem;
}

.main input{
  display: block;
  float: left;
  height: .35rem;
  border: .01rem solid #c6c6c6;
  border-radius: .04rem;
}

.main .upload{
  float: left;
  height: 1.66rem;
  width: 2.4rem;
  cursor: pointer;
  border: .01rem dashed;
  border-radius: .04rem;
  margin-right: .08rem;
  color: #959696;
  padding-top: .42rem;
  text-align: center;
}

.main .upload p{
  float: none;
  text-align: center;
  margin-bottom: .14rem;
  color: #959696;
}

.main .third_line input{
  width: 4.9rem;
}

.main .role-choice{
  height: .35rem;
  line-height: .35rem;
  margin-bottom: .18rem;
}

.main .education{
  height: .38rem;
  line-height: .38rem;
  margin-bottom: .18rem;
}

.main .education .time{
  float: left;
}

.main .education input{
  width: 1.95rem;
  height: .38rem;
  margin-right: .18rem;
}

.main .work-experience{
  height: .38rem;
  line-height: .38rem;
  margin-bottom: .18rem;
}

.main .work-experience input{
  width: 1.95rem;
  height: .38rem;
  margin-right: .18rem;
}

.main .award{
  height: .38rem;
  line-height: .38rem;
}

.main .award input{
  width: 1.95rem;
  height: .38rem;
  margin-right: .18rem;
}

.main .delete{
  float: left;
  /* width: .66rem; */
  height: .38rem;
  font-size: .16rem;
  color: #212121;
  margin-right: .16rem;
}

.main .add{
  float: left;
  /* width: .66rem; */
  height: .38rem;
  font-size: .16rem;
  color: #212121;
}

.main .delete button{
  width: .26rem;
  height: .26rem;
  background-color: #e0424b;
  border-radius: 50%;
  margin-right: .08rem;

}

.main .add button{
  width: .26rem;
  height: .26rem;
  background-color: #4fb565;
  border-radius: 50%;
  margin-right: .08rem;
}
/*对单选按钮的样式进行修改start*/
::v-deep .el-radio__input.is-checked .el-radio__inner{
  border-color: #ec414d!important;
  background: #ec414d!important;
}

::v-deep .el-radio__input.is-checked+.el-radio__label{
  color: #ec414d!important;
}
  /*对单选按钮的样式进行修改end*/

/*对个人简介输入框的格式start*/
::v-deep .el-textarea__inner{
  height: 1.66rem!important;;
  outline: #ec414d!important;
  resize:none!important;
}
/*对个人简介输入框的格式end*/
.main .submit{
  width: 2.92rem;
  height: .74rem;
  background-color: #ec414d;
  font-size: .26rem;
  color: #ffffff;
  border-radius: .05rem;
  margin-top: .2rem;
  margin-left: 4rem;
}

</style>